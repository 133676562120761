import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import Title from "./Title"
// import { FaGithubSquare, FaShareSquare } from "react-icons/fa"

// import Aos from "aos"
// import "aos/dist/aos.css"

export default function Projects() {
  const data = useStaticQuery(graphql`
    {
      allProjectJson {
        edges {
          node {
            demo
            description
            github
            title
            id
            website
            code
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  // useEffect(() => {
  //   Aos.init({ duration: 4000 })
  // }, [])

  return (
    <section className="section" id="projects">
      <Title title="projects" />
      <div className="section-center projects-center">
        {data.allProjectJson.edges.map(project => (
          <a href={project.node.demo} className="project">
            <article>
              {project.node.img && (
                <Image
                  fluid={project.node.img.childImageSharp.fluid}
                  className="project-img"
                />
              )}
              <div className="project-card">
                <h4>{project.node.title}</h4>
                <p>{project.node.description}</p>
                <div className="project-footer">
                  <div className="project-links">
                    <a href={project.node.demo} className="btn btn-card">
                      {project.node.website}
                      {/* <FaGithubSquare className="project-icon" /> */}
                    </a>
                    <a href={project.node.github} className="btn btn-card">
                      {project.node.code}
                      {/* <FaShareSquare className="project-icon" /> */}
                    </a>
                  </div>
                </div>
              </div>
            </article>
          </a>
        ))}
      </div>
    </section>
  )
}
