import React from "react"
import Title from "./Title"
import { FaHtml5, FaCss3Alt, FaSass, FaReact } from "react-icons/fa"
import { GrGatsbyjs, GrGraphQl } from "react-icons/gr"
import { DiGit } from "react-icons/di"
import { IoLogoJavascript } from "react-icons/io"

export default function Skills() {
  return (
    <section className="section bg-grey" id="skills">
      <Title title="skills" />
      <div className="section-center services-center skill-center">
        <IoLogoJavascript style={{ color: "#ffff00" }} className="skill-icon" />
        <FaHtml5 style={{ color: "#e34c26" }} className="skill-icon" />
        <FaCss3Alt style={{ color: "#264de4" }} className="skill-icon" />
        <FaSass style={{ color: "#cd6799" }} className="skill-icon" />
        <FaReact style={{ color: "#61dbfb" }} className="skill-icon" />
        <GrGatsbyjs style={{ color: "#663399" }} className="skill-icon" />
        <GrGraphQl style={{ color: "#e535ab" }} className="skill-icon" />
        <DiGit style={{ color: "#F1502F" }} className="skill-icon" />
      </div>
    </section>
  )
}
