import React from "react"
import { Link } from "gatsby"

const data = [
  {
    id: 1,
    text: "home",
    url: "/",
  },
  {
    id: 2,
    text: "projects",
    url: "/#projects",
  },
  {
    id: 3,
    text: "skills",
    url: "/#skills",
  },
]

export default function PageLinks({ styleClass }) {
  return (
    <ul className={`page-links ${styleClass ? styleClass : ""}`}>
      {data.map(link => (
        <li key={link.id}>
          <Link to={link.url}>{link.text}</Link>
        </li>
      ))}
    </ul>
  )
}
