import React from "react"
import Skills from "../components/Skills"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Projects from "../components/Projects"
import { graphql} from "gatsby"
import { Helmet } from "react-helmet"

export default function index({data}) {
  const { title } = data.site.siteMetadata
  return (
    <Layout>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          {/* <link rel="canonical" href="http://mysite.com/example" />     */}</Helmet>
      <Hero />
      <Projects />
      <Skills />
    </Layout>
    
  )
}

export const query = graphql`
    query SiteInfo {
      site {
        siteMetadata {
          author
          description
          title
        }
      }
    }

` 



