import React from "react"
import { RiMenuUnfoldFill } from "react-icons/ri"
import PageLinks from "../constants/pageLinks.js"

export default function Navbar({ toggleSidebar }) {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          {/* <img src={logo} alt="logo" /> */}
          <button type="button" className="toggle-btn" onClick={toggleSidebar}>
            <RiMenuUnfoldFill />
          </button>
        </div>
      </div>
      <PageLinks styleClass="nav-links" />
    </nav>
  )
}
