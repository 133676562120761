import React from "react"
// import { Link } from "gatsby"
import { FaLinkedin, FaGithub } from "react-icons/fa"

const data = [
  {
    id: "Linkedin",
    icon: <FaLinkedin className="social-icon"></FaLinkedin>,
    url: "https://www.linkedin.com/in/lihuacoding/",
  },
  {
    id: "Github",
    icon: <FaGithub className="social-icon"></FaGithub>,
    url: "https://github.com/lihuacoding",
  },
]

export default function SocialLinks({ styleClass }) {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>
      {data.map(link => (
        <li key={link.id}>
          <a href={link.url} alt={link.id} className="social-link">
            {link.icon}
          </a>
        </li>
      ))}
    </ul>
  )
}
