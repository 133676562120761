import React from "react"
import SocialLinks from "../constants/socialLinks"

export default function Footer() {
  return (
    <footer className="footer">
      <SocialLinks styleclass="footer-links"></SocialLinks>
      <h4>copyright &copy;{new Date().getFullYear()} created by li hua</h4>
    </footer>
  )
}
