import React from "react"
// import { Link } from "gatsby"
// import SocialLinks from "../constants/socialLinks"
import Particles from "react-particles-js"
import Typist from "react-typist"

export default function Hero() {
  return (
    <div className="hero">
      <div className="scale-in-center" id="welcome">
        <header className="header">
          <Typist cursor={{ show: false }}>
            <Typist.Delay ms={500} />
            <h2 className="hero-text">
              Hi, I'm <span className="hero-span">Li Hua</span>
            </h2>
            <h2 className="hero-text">Web developer</h2>
            <h3 className="hero-text"> on the way to be A&nbsp;</h3>
            <h3 className="hero-text hero-span">full stack developer</h3>
          </Typist>
          <Typist cursor={{ show: false }}>
            <Typist.Delay ms={5500} />
            {/* <SocialLinks /> */}
            <a href="mailto:lihuacoding@gmai.com" className="btn hero-btn">
              Say Hello
            </a>
          </Typist>
        </header>
      </div>
      <Particles
        id="particles-js"
        params={{
          particles: {
            number: {
              value: 300,
              density: {
                enable: true,
                value_area: 1000,
              },
            },
            color: {
              value: "#fff",
            },
            opacity: {
              // value: 0.8,
              anim: {
                enable: true,
              },
            },
            size: {
              value: 7,
              random: true,
              anim: {
                enable: true,
                speed: 3,
              },
            },
            line_linked: {
              enable: false,
            },
            move: {
              speed: 0.2,
            },
          },
        }}
      />
    </div>
  )
}
